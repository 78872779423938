@use "@/styles/variables" as *;

.orderSummaryDropdown {
  width: 100%;

  > .button {
    width: 100%;

    > .header {
      display: flex;
      justify-content: space-between;

      font-family: $body-font, sans-serif;
      font-weight: $font-weight-bold;
      font-size: 15px;
      color: $color-black;
      text-decoration: underline;

      padding: 15px 0;
    }
  }

  > .contentWrapper {
    transition: max-height 250ms;
    max-height: 0;
    overflow-y: hidden;

    > .content {
      opacity: 0;
      height: fit-content;
    }

    &.visible {
      max-height: 50vh;

      > .content {
        opacity: 1;
      }
    }
  }
}
