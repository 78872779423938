@use "@/styles/variables" as *;

.container {
  border-top: 3px dashed $color-gray-3;
  padding: $space-md $space-xs;
  background-color: $color-gray-1;

  h1 {
    font-family: $body-font;
    font-size: $font-size-sm;
    font-weight: $font-weight-bold;
    letter-spacing: $letter-spacing-headline;
    text-transform: uppercase;
    margin: 0;
  }

  p {
    margin: $space-md 0;
  }
}

.address {
  display: flex;
  flex-direction: column;
  margin-top: $space-md;

  span + span {
    margin-top: $space-xxxs;
  }
}

.formRow {
  display: flex;
  margin: $space-md -8px;
}

.inputBlock {
  padding: 0 $space-xxs;
}

.wFull {
  width: 100%;
}

.w50 {
  width: 50%;
}

.addressLineBtn {
  margin-top: $space-xs;
  font-weight: $font-weight-bold;
}
