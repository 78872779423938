@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.utilityWrapper {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  height: $header-utility-bar-height;
  padding: 0 $space-xxl;
  justify-content: space-between;
  background-color: $color-white;
  position: relative;
}

.promoLink {
  padding: 0 $space-md;
  text-transform: uppercase;
}

.utilityLinks {
  display: flex;
  flex-direction: row;
  padding-top: $space-xxxxs;

  .storeLocator,
  .account {
    display: flex;
    text-transform: uppercase;
    align-items: center;
    font-weight: $font-weight-bold;
    margin-right: $space-xxs;
  }

  .storeLocator {
    .link {
      display: flex;
      align-items: center;
    }

    .icon {
      display: inline-block;
      margin: $space-xxxxs 6px 0;
    }
  }
}

.flag {
  margin: 9px 6px 0 9px;
  border: 1px solid $color-header-border;
  max-height: $space-sm;
}
