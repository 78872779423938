@use "@/styles/variables" as *;
@use "../styles.base.module" as *;

.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: $space-xxs;
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(calc(-50%)) translateY(-10px);
  box-shadow: rgb(128 128 128) 0 2px 10px 0;
  z-index: $user-layer;
  background-color: $color-header-dropdown-background;

  &::after {
    pointer-events: none;
    content: " ";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -10px;
    height: 0;
    width: 0;
    border: 10px solid rgb(255 255 255 / 0%);
    border-bottom-color: $color-white;
  }

  // This is the default "hidden" state:
  opacity: 0; // Completely transparent...
  pointer-events: none; // ...and unclickable

  // Switch opacity gracefully
  transition: opacity 150ms, transform 150ms;

  &.visible {
    // This is the "visible" state:
    opacity: 1; // Completely opaque
    transform: translateX(calc(-50%)) translateY(0); // ...only the X translation...
    pointer-events: all; // ...and clickable.
  }
}

.links {
  display: flex;
  flex-direction: column;
  padding: $space-xs 0;
  list-style: none;
  margin: 0;
  white-space: nowrap;
  line-height: $line-height-relaxed;
}

.linkWrapper {
  display: flex;
  color: $color-header-dropdown-foreground;
}

.buttonWithIcon {
  text-align: left;
  color: $color-header-foreground;
}


.link,
.logoutIcon {
  display: inline-block;
  margin-right: $space-xxxs;
  vertical-align: middle;
}

.link,
.buttonWithIcon,
.button {
  font-weight: normal;
  padding: $space-xxxs $space-md;
  text-decoration: none;
  white-space: nowrap;
  line-height: $line-height-relaxed;
  font-family: $body-font;
  width: 100%;
  text-align: left;
}

.spinner {
  width: 25px;
  height: 25px;
  margin: 0 16px;
}
