@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

@forward "./styles.base.module.scss" with (
  $color-button-height: 80px
);

@use "./styles.base.module.scss" as *;

.container {
  margin: 0;
}

.currentColor {
  margin: $space-xs 0;
  text-transform: uppercase;
  letter-spacing: 1px;

  .value {
    font-weight: $font-weight-base;
  }
}

.color {
  width: $color-button-height; // Square

  background-color: $color-secondary-100;

  border: 1px solid $color-secondary-100;
  border-radius: 0;

  transition: all 0.15s ease-out;
}

.selected {
  pointer-events: none;
  border: 1px solid $color-black;
}
