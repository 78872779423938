@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.wrapper {
  border: 1px solid $color-checkout-border;
  flex: 1;
  width: 100%;

  @include md-min {
    margin-bottom: $space-base;
  }
}

.top {
  height: 40px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  background-color: $color-checkout-header;
  font-weight: $font-weight-bold;
  font-size: $font-size-sm;
  padding: 0 10px;

  @include md-min {
    padding: 0 $space-base;
  }
}

.body {
  background-color: $color-white;
}

.footer {
  border-top: 1px solid $color-content-border;
  padding: $space-base;
}

.orderContent {
  display: flex;
  padding: $space-base;
}

.details {
  display: flex;
  flex-direction: column;
  gap: $space-xxs;
  flex: 1;
}

.progressBarWrapper {
  margin: $space-base 0;
}

.shipmentProgressWrapper {
  margin-bottom: 30px;

  button {
    font-weight: $font-weight-bold;
  }
}

.shipmentProgress {
  list-style-position: inside;
  padding: 0;

  li + li {
    margin-top: $space-xxxs;
  }
}

.productWrapper {
  margin-top: 30px;

  h2 {
    font-family: $heading3-font;
    font-size: $font-size-sm;
    margin: 0 0 $space-sm;
  }
}

.trackButton {
  margin-top: 30px;
}

.product {
  display: flex;
}

.productInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  span {
    font-size: $font-size-xs;
    margin-bottom: 6px;
  }

  button {
    font-weight: $font-weight-bold;
    font-size: $font-size-sm;
  }
}

.productImg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 76px;
  margin-right: $space-xs;
  transition: all 0.5s;

  @include lg-min {
    width: 92px;
  }
}

.totalsWrapper {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid $color-content-border;
  margin-top: $space-base;
  padding-top: 10px;
}

.column {
  display: flex;
  flex-direction: column;
}

.columnTitle {
  margin-bottom: 6px;
}

.right {
  text-align: right;
}

.input {
  label {
    margin-top: $space-sm;
    margin-bottom: $space-xxs;
  }
}

.bottomWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.trackingWrapper {
  margin-bottom: $space-base;
}
