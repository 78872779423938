$button-height: 36px;

.payPalButtonContainer {
  box-sizing: border-box;

  height: $button-height;
}

:export {
  buttonHeight: $button-height;
}
