@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.nextStepBtn {
  margin-top: $space-xs;
}

.emailWrapper {
  margin: $space-xs 0;
}
