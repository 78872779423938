@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

$color-button-height: 50px !default;
$color-selector-spinner-color: $color-primary !default;

:export {
  colorSelectorSpinnerColor: $color-selector-spinner-color;
}

.container {
  margin: $space-sm 0 $space-xs;
  overflow-x: hidden;

  > .skeletonLoader {
    // Height of the skeleton loader =
    //  2 x space-xs to account for the vertical margins of the color label.
    // + Height of the font of the color label.
    // + Bottom margin of the color boxes
    // + Height of the color boxes

    height: (2 * $space-xs) + $font-size-xs + $space-xs + $color-button-height;
  }
}

.currentColor {
  margin: $space-xs 0;
  font-weight: $font-weight-regular;
  font-size: $font-size-xs;

  .value {
    padding-left: $space-xxxs;
    font-weight: $font-weight-bold;
  }
}

.colors {
  display: flex;
  flex-wrap: wrap;
}

.colorsRow {
  @include md-max {
    display: inline-block;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    width: 100%;
    vertical-align: middle;
  }
}

.color {
  position: relative;

  margin-right: $space-xs;
  margin-bottom: $space-xs;
  width: 63px;
  height: $color-button-height;
  background-color: $color-white;
  cursor: pointer;

  box-sizing: border-box;
  border: 1px solid $color-gray;
  border-radius: 2px;
}

.selected {
  pointer-events: none;
  border: 1px solid $color-black;
}

.outOfStock {
  color: $color-disabled;
  background-color: $out-of-stock-background;

  /** The line that crosses out the selector when disabled. */
  &::after {
    content: "";
    width: 140%;
    height: 1px;
    background-color: $color-gray;
    position: absolute;
    top: 50%;
    left: -16px;
    transform: rotate(45deg);
  }
}
