@use "@/styles/variables" as *;

.title {
  display: block;
  margin-top: $space-lg;
  font-size: $font-size-lg;
}

.inputBlock {
  margin-top: $space-base;
}

.w50 {
  width: 50%;
}

.applyBtn {
  margin-top: $space-xs;
}

.giftCardWrapper {
  display: flex;
  flex-direction: column;
  margin: $space-base 0;
}

.giftCardContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: $space-xxs 0;
}

.giftCard {
  display: flex;
  flex-direction: column;
}

.errorMessage {
  color: #560000;
  border: 1px solid #e6b8b8;
  background-color: #edcccc;
  text-align: center;
  margin: $space-base 0;
  padding: $space-xs $space-base;
}

.balanceMessage {
  color: #213d21;
  background-color: #d9e4d9;
  border: 1px solid #c9d9ca;
  text-align: center;
  margin: $space-base 0;
  padding: $space-xs $space-base;
}
