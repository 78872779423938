@use "@/styles/variables" as *;

$top-padding: 10px;
$side-padding: 15px;
$close-icon-size: 15px;
$color-banner-default-bg: $color-primary-dark !default;

.banner {
  background-color: $color-banner-default-bg;

  width: 100%;
  min-height: $close-icon-size + ($top-padding * 2);
  padding: $top-padding $side-padding;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  color: $color-white;
  text-align: center;
  font-weight: $font-weight-bold;

  transition: 150ms;

  &.closed {
    transform: translateY(-100%);
    opacity: 0;
  }
}

.closeIconContainer {
  position: absolute;

  right: $side-padding;
  top: calc(50% - ($close-icon-size / 2));
}

.closeIcon {
  color: $color-white;
}
