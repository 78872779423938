@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.title {
  margin: 0;
  margin-bottom: $space-xs;
  font-size: $font-size-sm;
  font-family: $body-font;
  font-weight: $font-weight-bold;
  letter-spacing: $letter-spacing-headline;
}

.subtitle {
  margin: $space-xs 0;
}

.list {
  display: flex;
  gap: 5px;

  align-items: center;
  justify-content: space-between;

  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

.listItem {
  width: 100%;
}

.container {
  display: flex;
  flex-direction: column;

  align-items: center;
  border: 1px solid $color-checkout-border;
  padding: $space-sm $space-xxl;
  margin-bottom: $space-xxs;

  // For the express options in checkout:
  //
  // - Display buttons as a horizontal row
  // - Display the "Express Payment Options" title on top
  // - Display the "Or continue below..." subtitle
  &.checkout {
    .list {
      flex-direction: column;

      @include lg-min {
        flex-direction: row;
      }
    }
  }

  // For the express options in cart:
  //
  // - Display buttons vertically
  // - Display the "Express Payment Options" title on top
  // - HIDE the "Or continue below..." subtitle
  &.cart {
    padding: 0;
    border: none;

    .list {
      flex-direction: row;
    }

    .subtitle {
      display: none;
    }

    .title {
      display: none;
    }

    @include lg-min {
      margin-bottom: 0;
      margin-top: $space-xs;

      .list {
        flex-direction: column;
      }

      .title {
        display: block;
      }
    }
  }

  // For the express options in minicart:
  //
  // - Display buttons horizontally
  // - HIDE the "Express Payment Options" title on top
  // - HIDE the "Or continue below..." subtitle
  &.minicart {
    padding: 0;
    border: none;

    .list {
      flex-direction: row;
    }

    .subtitle {
      display: none;
    }

    .title {
      display: none;
    }
  }
}
