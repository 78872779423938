@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.paymentForm {
  padding: $space-md $space-xs;

  h2 {
    margin-bottom: $space-xs;
    font-family: $body-font;
    font-size: $font-size-sm;
    font-weight: $font-weight-bold;
    text-transform: capitalize;
    letter-spacing: $letter-spacing-headline;
  }
}

.subtitle {
  font-size: $font-size-md;
  margin: 0;

  @include md-min {
    font-size: $font-size-lg;
  }
}

.creditCardFieldsWrapper {
  display: contents;
}

.formRow {
  display: flex;
  margin: 15px 0;
}

.nestedForm {
  width: 100%;
  margin: 15px 0;
}

.nestedFormRow {
  display: flex;
  justify-content: space-between;
}

.nestedInputBlock {
  // Subtract 8px from every input block so there's a 16px gap on the middle
  // due to `justify-content: space-between;`
  width: calc((50% - 8px));
}

.inputBlock {
  padding: 0;
}

.wFull {
  width: 100%;
}

.w50 {
  width: 50%;
}

.error {
  display: block;
  margin-top: $space-xxs;
  color: $color-error-dark;
}

.couponsContainer {
  margin-top: 7px;
  margin-bottom: 26px;
}

.securityCodeWrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.tooltipWrapper {
  display: flex;
  height: 36px;
  align-items: center;
  margin-left: $space-sm;
}

.securityCodeHelperText {
  font-weight: $font-weight-bold;
  text-decoration: underline;
  display: block;
  padding: 15px 0 0;
}

// Card Classes
.card {
  position: relative;
}

.card::after {
  opacity: 0;
  content: "";
  position: absolute;
  background-image: url("/images/payment-types.png");
  background-repeat: no-repeat;
  height: 29px;
  width: 47px;
  top: 29px;
  right: 4px;
  background-size: 288px 182px;
}

.amex::after {
  opacity: 1;
  background-position: -48px 0;
}

.mastercard::after {
  opacity: 1;
  background-position: -192px 0;
}

.discovercard::after {
  opacity: 1;
  background-position: -241px 0;
}

.visa::after {
  opacity: 1;
  background-position: -96px 0;
}

.howPayPalWorksLinkContainer {
  margin-bottom: $space-sm;
}

.howPayPalWorksLink {
  font-weight: bold;
  text-decoration: underline;
}

.payPalRedirectNotice {
  line-height: 1.3;
  margin-bottom: $space-sm;
}

.payPalAccountEmail {
  margin-bottom: $space-xs;
  font-weight: bold;
}

.useDifferentPayPalAccount {
  margin-bottom: $space-xs;
}
