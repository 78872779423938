@use "@/styles/variables" as *;

.orderSummaryCoupons {
  > .applyForm {
    display: flex;
    flex-direction: column;

    .label {
      font-family: $body-font, sans-serif;
      font-weight: normal;
      font-size: 15px;
      color: $color-black;

      margin-bottom: 7px;
    }

    .input {
      height: 36px;
      margin-bottom: 10px;
    }

    .button {
      border-width: 2px;
    }
  }

  > .errorBox {
    display: block;
    margin-top: 13px;
    color: $color-danger-600;
  }
}
