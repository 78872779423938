@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.exchangeBanner {
  background-color: $color-exchange-ui-bg;
  color: $color-white;

  font-family: $body-font;
  font-size: $font-size-xs;
  line-height: 1.615;
  font-weight: $font-weight-bold;

  display: flex;
  flex-direction: column;

  @include md-min {
    flex-direction: row;

    font-size: $font-size-md;
    line-height: 1.4;
  }
}

.linkContainer {
  @include md-min {
    margin-left: 10px;
  }
}

.link {
  font-family: $body-font;
  font-size: $font-size-xs;
  line-height: 1.615;
  font-weight: $font-weight-bold;

  text-decoration: underline;

  @include md-min {
    font-size: $font-size-md;
    line-height: 1.4;
  }
}
