@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

@forward "./styles.base.module.scss";

.category {
  font-size: $font-size-xs;
  font-weight: $font-weight-base;
  text-transform: uppercase;
  letter-spacing: $letter-spacing-relaxed;
}

.active {
  background-color: $color-black;
  color: $color-white;

  &::before {
    border-color: transparent transparent transparent $color-black;
  }
}

.categoryLabel {
  span {
    font-size: $font-size-lg;
    font-weight: $font-weight-base;
  }
}

.table {
  max-width: 550px;
}

.modal {
  .modalHeader {
    h3 {
      font-size: $font-size-xxl;
      font-weight: $font-weight-base;
      text-transform: none;
    }
  }
}

.spinner {
  width: 25px;
  height: 25px;
  margin-right: auto;
  margin-left: 10px;
  margin-top: 16px;
}
