.sizeChartSkeleton {
    height: fit-content;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.tableLine {
    display: flex;
    width: 100%;
}

.tableCell {
    flex: 1;
    height: 30px;
    width: 100px;
    margin: 3px;
}
