.navigationIcon {
    width: 32px;
    height: 32px;

    &.left {
        margin: 4px 8px 8px 0;
    }

    &.right {
        margin: 4px 0 8px 8px;
    }
}
