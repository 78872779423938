@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

@forward "./styles.base.module.scss";

.size {
  min-width: calc($space-lg + 4px);
  min-height: calc($space-lg + 4px);
  line-height: 1;
  border: 1px solid $color-black;
  border-radius: 0;
  transition: all 0.15s ease-out;
  font-size: $font-size-xs;
  padding: $space-xxxxs;
  margin: 0 0 $space-xxxs $space-xxxs;
}

.selected {
  background-color: $color-black;
  color: $color-white;
}
