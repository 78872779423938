@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

$order-summary-bg-color: $color-white-gray !default;

.container {
  width: 100%;
  padding: 11px;
  background-color: $order-summary-bg-color;
  border: 1px solid rgba(204, 204, 204, 1);
  box-shadow: $card-box-shadow;

  @include md-min {
    display: flex;
    flex-flow: column;
    margin-top: 0;
  }
}

.selectField {
  select {
    margin-top: $space-xxxs;
  }

  @include md-min {
    order: 1;
  }
}

.summary {
  margin-top: $space-sm;

  @include md-min {
    order: 2;
  }
}

.summaryInfo {
  display: flex;
  justify-content: space-between;

  // Apply margin top when there is a repetition of the same element (.summaryInfo in this case).
  & + & {
    margin-top: $space-xxs;
  }

  &:last-child {
    font-weight: $font-weight-bold;
  }

  span {
    font-size: $font-size-sm;
  }
}

.checkout {
  border-top: 1px solid $color-gray-2;
  padding-top: $space-sm;
  margin-top: $space-sm;
  display: flex;
  flex-direction: column;
  gap: $space-xxs;

  @include md-min {
    border: 0;
    order: 0;
    padding: 0;
    margin: $space-sm 0;
  }
}
