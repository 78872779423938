@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

// This holds the fallback content that is displayed while the decision is loading or if it fails
// to retrieve an appropriate decision. This should fill the space of the pending decision that way
// there is layout shift when the first decision is loaded. This also allows the fallback to be
// a proper component in case the configuration is not yet complete. In general it should not be used
// for that purpose.
.fallbackWrapper {
  display: block;
  position: absolute;
  visibility: visible;
  inset: 0;
}

.loadingWrapper {
  display: none;

  // The first child has its visibility set to hidden rather than being undisplayed.
  // This is done in order to:
  // 1. ensure the container is still sized properly while the final decision loads
  // 2. nested resources such as images can still begin to load.
  &:first-child {
    display: contents;

    .hiddenDecision {
      position: relative;
      visibility: hidden;
    }
  }
}
