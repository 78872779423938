@use "@/styles/variables" as *;

.totalLine {
    display: flex;
    width: 100%;

    transition: color 250ms;

    .totalsList &:not(:first-child) {
      margin-top: 12px;
    }

    &.bold {
      font-weight: $font-weight-bold;
    }
  }

.spacer {
    flex: 1;
    margin: 0 6px;

    &.dotted,
    .dottedSeparators & {
        border-bottom: 1px dashed #0004;
    }
}
