@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

$highlight: $color-lighter-gray;
$category-width: 120px !default;

.sizeChart {
  max-width: fit-content;
  margin: 0 auto;
}

.wrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 23px;
}

.column {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: flex-start;
  gap: 6px;
  padding-top: 36px;
}

.category {
  line-height: 42px;
  height: 42px;
  font-weight: $font-weight-bold;
  background-color: $highlight;
  text-transform: uppercase;
  padding: 0 7px;
  font-size: $font-size-xs;
  position: relative;
  width: $category-width;

  &:has(span) {
    padding-top: 7px;
    line-height: unset;
  }

  span {
    font-size: 10px;
    text-transform: initial;
    display: block;
    margin-top: 3px;
  }

  &:hover {
    cursor: pointer;
  }
}

.active {
  background-color: $color-primary;
  color: $color-white;
  cursor: pointer;

  &::before {
    content: "";
    top: 13px;
    right: -7px;
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7px 0 7px 7px;
    border-color: transparent transparent transparent $color-primary;
  }
}

.tableWrapper {
  overflow: hidden;
  width: calc(100vw - 174px);

  @include md-min {
    width: auto;
  }
}

.table {
  letter-spacing: 0.5px;
  overflow-x: auto;
}

.tableHeadRow {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.measurement {
  pointer-events: none;
}

.tableHead {
  text-align: center;
  background-color: $color-gray-1;
  min-width: 100px;
  height: 35px;
  line-height: 35px;
  text-transform: uppercase;
  font-size: $font-size-xs;
  position: relative;

  &:has(span) {
    padding-top: 6px;
    line-height: unset;
  }

  span {
    font-size: 10px;
    display: block;
    color: #898989;
  }
}

.tableRow {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.tableCell {
  margin-top: 1px;
  background-color: $color-gray-1;
  text-align: center;
  min-width: 100px;
  height: 35px;
  line-height: 35px;
  font-size: $font-size-xs;
}

.showMoreCountries {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.button {
  font-weight: $font-weight-bold;
  font-size: $font-size-xs;
  color: $color-primary;
  text-decoration: underline;
}

.hover {
  background-color: $highlight;
}

.skeletonLoader {
  margin-top: 5px;
}

.categoryLabel {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}

.spinner {
  width: 30px;
  height: 30px;
  margin-right: auto;
  margin-left: 10px;
}
