@use "@/styles/variables" as *;

.container {
  display: flex;
  align-items: center;

  border: 1px solid #CCC;
  padding: $space-xs $space-xxs;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;

  & + & {
    margin-top: $space-xs;
  }

  &:hover {
    background-color: $color-white-gray;
  }

  &:has(input:checked) {
    background-color: $color-white-gray;
  }

  input {
    margin-right: $space-xs;
  }
}

.content {
  display: flex;
  align-items: center;

  span {
    line-height: 10px;
    margin-left: $space-xxs;
  }

  p {
    margin: $space-xxs 0 0;
  }
}
