@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

@forward "./styles.base.module.scss";

.container {
  padding: 0 $space-lg;
}

.sizeSelectorTop {
  display: none;
}

.sizes {
  display: flex;
  flex-wrap: wrap;
}

.sizeChart {
  font-size: $font-size-xs;
  font-weight: $font-weight-base;
  text-decoration: underline;
  letter-spacing: $letter-spacing-small-text;
  color: $color-primary;

  &:hover {
    text-decoration: none;
  }
}

.ruler {
  display: none;
}

.modal {
  .modalHeader {
    h3 {
      font-size: $font-size-xxl;
      font-weight: $font-weight-base;
      text-transform: none;
    }
  }
}
