@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.container {
  margin: $space-sm 0;
}

.sizeSelectorTop {
  display: flex;
  justify-content: space-between;
}

.sizeSelectMsg {
  font-size: $font-size-xs;
  margin-bottom: $space-xs;

  .value {
    font-weight: $font-weight-bold;
    padding-left: $space-xxxs;
  }
}

.statusMessage {
  font-weight: $font-weight-bold;

  // Start invisible, and fade in with transition
  opacity: 0;
  visibility: hidden;

  &.visible {
    opacity: 1;
    visibility: visible;

    // Only animate the opacity when the status message becomes visible.
    // This produces a fade-in effect, but not a fade-out effect when the
    // status message is hidden. This is intentional, since React will update
    // the status message text immediately when it changes, but we don't want
    // to show the "non-visible" status message at all. Therefore, we must
    // hide the status message immediately (i.e. without transition).
    transition-property: opacity;
    transition-duration: 150ms;
  }
}

.sizes {
  display: grid;
  gap: $space-xxs;
  grid-template-columns: 1fr 1fr 1fr;
}

.warning {
  color: $color-danger-900;
}

.dropdownContainer {
  display: block;

  .sizeSelectMsg {
    font-size: $font-size-xxs;
    margin-bottom: 0;
  }

  .sizes {
    display: block;
  }
}

.rating {
  width: 32px;
  height: 32px;
}

.ruler {
  margin-right: $space-xxxs;
}

.sizeRow {
  display: flex;
  justify-content: space-between;
}

.sizeCallout {
  display: none;
  margin: $space-xs 0;
  text-transform: uppercase;
  font-size: $font-size-xs;
  font-weight: $font-weight-bold;
  letter-spacing: $letter-spacing-headline;

  @include lg-min {
    display: block;
  }
}

.sizeChart {
  display: flex;
  align-items: center;
  margin: $space-xs 0;
  font-size: $font-size-xs;
  font-weight: $font-weight-bold;
}

.modal {
  // width: 300px;

  .modalHeader {
    background-color: $bg-grey;

    h3 {
      text-transform: uppercase;
      font-weight: $font-weight-bold;
      margin: 0;
    }
  }

  .modalBody {
    max-width: 560px;
    min-width: min-content;
    min-height: 820px;
    padding: 15px;
  }
}
