@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.size {
  color: $color-black;
  position: relative;
  min-height: $space-xl;
  line-height: $space-xl;
  text-align: center;
  cursor: pointer;
  border: 1px solid $color-gray;
  background-color: $color-light;
  border-radius: 2px;

  &:disabled {
    pointer-events: none;
  }

  &:hover {
    border: 1px solid $color-black;
    background-color: $color-black;
    color: $color-white;
  }

  &.selected {
    border: 1px solid $color-black;
    background-color: $color-black;
    color: $color-white;
  }
}

.outOfStock {
  color: $color-disabled;
  background-color: $out-of-stock-background;
  position: relative;

  /** The line that crosses out the selector when disabled. */
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;

    background: linear-gradient(
      to top right,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) calc(50% - 0.8px),
      rgba(0, 0, 0, 0.25) 50%,
      rgba(0, 0, 0, 0) calc(50% + 0.8px),
      rgba(0, 0, 0, 0) 100%
    );
  }
}


.unselectable {
  opacity: 0.5;
}
