@use "@/styles/variables" as *;

.orderSummaryItemList {
  width: 100%;
  display: flex;
  flex-direction: column;

  .orderSummaryItem {
    display: flex;

    width: 100%;
    padding: 8px 0;

    &:not(:first-child) {
      border-top: 1px solid #0001;
    }

    > .textContainer {
      flex: 1;

      font-family: $body-font, sans-serif;
      font-size: 15px;
      line-height: 21px;
      color: $color-black;

      > .name {
        font-weight: $font-weight-bold;
      }

      > .description {
        font-weight: normal;
      }
    }

    > .closeButtonContainer {
      margin-left: 15px;
    }
  }
}
