@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.container {
  display: flex;
  gap: $space-xs;
}

body .active {
  background-color: $color-black;
  border-color: $color-black;
  color: $color-white;
}

.button {
  min-width: 140px;
}
