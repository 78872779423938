@use "@/styles/variables" as *;

.fillWidth {
  width: 100%;
}

.pending {
  animation-name: color-cycle;
  animation-duration: 750ms;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.45, 0, 0.55, 1);
  animation-fill-mode: backwards;
}

.loaded {
  animation-name: color-cycle-loaded;
  animation-duration: 200ms;
  animation-iteration-count: 2;
  animation-timing-function: linear;
  animation-fill-mode: backwards;
}

// Cycle from a fully transparent black to a very slightly opaque black.
@keyframes color-cycle {
  0% {
    color: rgba($color: $color-black, $alpha: 1);
  }

  50% {
    color: rgba($color: $color-black, $alpha: 0.25);
  }

  100% {
    color: rgba($color: $color-black, $alpha: 1);
  }
}

// Use another set of the same keyframe here to play
// the `loaded` animation correctly.
@keyframes color-cycle-loaded {
  0% {
    color: rgba($color: $color-black, $alpha: 1);
  }

  50% {
    color: rgba($color: $color-black, $alpha: 0.25);
  }

  100% {
    color: rgba($color: $color-black, $alpha: 1);
  }
}
