@use "@/styles/variables" as *;

.container {
  margin-bottom: $space-xs;
  padding: $space-sm $space-xs;
  border: 1px solid $color-gray-2;
}

.link {
  font-weight: $font-weight-bold;
}
