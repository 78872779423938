@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.tooltipBody {
  line-height: 1.36;
  display: flex;
  flex-direction: column;

  .headline {
    margin: 0 0 $space-xs;
    text-transform: uppercase;
    letter-spacing: 1;
    font-size: $font-size-base;
  }

  .image {
    margin-right: 10px;
  }

  .textCard {
    margin: 0 $space-xs;
    font-size: $font-size-sm;
    display: flex;
    align-items: center;
  }
}

.tooltipTextWrapper {
  display: flex;
  margin: $space-xs 0;
}
