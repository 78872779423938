@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

$spacing: 8px;

.paymentListItem {
    & + & {
        margin-top: $spacing;
        padding-top: $spacing;
        border-top: 1px solid $color-gray-divider;
    }
}
