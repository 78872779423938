@use "@/styles/variables" as *;

.colorSelector {
  display: flex;
  justify-content: center;

  width: 100%;
  margin-bottom: 6px;

  .colorOption {
    width: 14px;
    height: 14px;

    // Make it so the color bubbles are circular and have a faint outline.
    border: 1px solid rgba($color: $color-black, $alpha: 0.1);
    border-radius: 100%;

    margin: 0 2.5px;

    cursor: pointer;
  }
}
