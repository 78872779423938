.button {
    margin: 0;
    padding: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.swatch {
    height: 32px;
    width: 32px;
    margin-left: 2px;
    border-radius: 50%;

    &.active {
        height: 26px;
        width: 26px;
        margin: 3px;
        border: 1px solid #fff;
        outline-offset: 1px;
        outline: 1px solid #222;
    }
}
