@use "@/styles/variables" as *;

.subtitle {
  margin-top: 0;
  margin-bottom: $space-xxs;
  font-family: $body-font;
  font-size: $font-size-sm;
  font-weight: $font-weight-bold;
  letter-spacing: $letter-spacing-headline;
}

.data {
  display: flex;
  flex-direction: column;
  margin-bottom: $space-md;

  span + span {
    margin-top: $space-xxxs;
  }

  .brand {
    text-transform: capitalize;
  }
}

.paymentList + .paymentList {
  border-top: 1px solid $color-border;
}
